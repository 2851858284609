exports.onRouteUpdate = ({
    location
}) => {
  track_page_view(location)
}

const track_page_view = location => {
  const { ga, excludeGAPaths } = window

  // Don't track while developing.
  if (process.env.NODE_ENV === "production" && typeof ga === "function") {
    if (
      location &&
      typeof excludeGAPaths !== "undefined" &&
      excludeGAPaths.some(
        x => x.test(location.pathname)
      )
    ) {
      return
    }

    const pageLocation = location
      ? location.pathname + location.search + location.hash
      : undefined

    ga("set", "page", pageLocation)
    ga("send", "pageview")
  }
}
